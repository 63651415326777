import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { IEmailUserAction } from "../models/IEmailUserAction";
import JwtToken from "../models/JwtToken";
import httpService from "./httpService";

const apiEndpoint = "/Authentication";
//export const tokenKey = "token";
//export const accessKey = "access";

//setupHttpServiceJWT()

//async function setupHttpServiceJWT() {
//    await httpService.setJwt(getJwt());
//}

export async function login(email: string, password: string, securityCode: string, requestTfaRemoval: boolean) {
    const { status, data: loginResponse } = await httpService.post(apiEndpoint + "/login", { email, password, securityCode, requestTfaRemoval });

    if (status === 202) return 1; //TFA information needed, or TFA Removal request accepted.

    if (status === 200) {
        //loginWithJwt(loginResponse.token);
        //Cookies.remove("BCBSession");
        return 2;
    }

    return 0;
}

//export async function loginWithJwt(jwt: string) {
//    localStorage.setItem(tokenKey, jwt);
//    setupHttpServiceJWT();
//}

export function logout() {
    //localStorage.removeItem(tokenKey);
    //localStorage.removeItem(accessKey)
    //setupHttpServiceJWT();
}

async function refreshToken() {
    const currentUser = getCurrentUser();
    if (currentUser) {
        const fiveMinutesFromNow: Date = new Date(Date.now() + 5 * 60 * 1000);
        
        if (currentUser.expiry < fiveMinutesFromNow) {
            const refreshTokenRoute = window.__RUNTIME_CONFIG__.EXTERNAL_LOGIN ? "/../account/refreshToken" : apiEndpoint + "/refreshToken";
          
            const { status, data: loginResponse } = await httpService.get(refreshTokenRoute);
            if (status === 200) {
                //if (!window.__RUNTIME_CONFIG__.EXTERNAL_LOGIN) {
                //    Cookies.set("BCBSession", loginResponse.token );
                    //await loginWithJwt(loginResponse.token);
                //}
            }
        }
    }
}

export function tokenExpired(): boolean{
    const jwt = getJwt();
    if (jwt) {
        const decodedToken: any = jwt_decode(jwt);

        const expiry: Date = new Date(decodedToken.exp * 1000);
        const now: Date = new Date(Date.now());

        if (expiry > now) {                
            return false;
        }
    };
    return true;
}

export function getCurrentUser(): JwtToken | null {
    try {
        const jwt = getJwt();
        if (jwt) {
            const decodedToken: any = jwt_decode(jwt);

            const expiry: Date = new Date(decodedToken.exp * 1000);
            const now: Date = new Date(Date.now());

            if (expiry < now) {
                logout(); //The JWT Has expired, so there's no point keeping it laying around
                return null;
            }

            const jwtToken: JwtToken = {
                expiry: expiry,
                email: decodedToken.email,
                displayName: decodedToken.displayName,
                userId: decodedToken.userId,
                securityPrivileges: JSON.parse(decodedToken.securityPrivileges)
            };

            return jwtToken;
        } else return null;
    } catch (ex) {
        return null;
    }
}

export function getJwt(): string | null {
    const bcbsession = Cookies.get("BCBSession");
    if (bcbsession){
        return bcbsession; 
        //loginWithJwt(bcbsession);
    }
    return null;
    //return localStorage.getItem(tokenKey);
}

export async function forgotPassword(email: string) {
    await httpService.post(apiEndpoint + "/forgotPassword", { email });
}

async function completeEmailAction(action: IEmailUserAction) {
    const { status } = await httpService.post(apiEndpoint + "/completeEmailAction", action);

    if (status === 200) {
        return 1;
    }

    return 0;
}

export function hasToken(): boolean{
    const jwt = getJwt();
    if (jwt)
        return true;

    return false;
}

export function hasAccess( accessKey : string | string[]) : boolean  {
    var currentUser = getCurrentUser()!;

    if ( typeof accessKey == "string" ){
    
        const result = currentUser.securityPrivileges.filter( x => x === accessKey);
        if (result.length > 0)
            return true;
    }
    else {
        for( const access of accessKey){
            const result = currentUser.securityPrivileges.filter( x => x === access);
            if (result.length > 0)
                return true;
        }
    }

    return false;
}

const authentication = {
    //tokenKey,
    login,
    logout,
    getCurrentUser,
    refreshToken,
    forgotPassword,
    completeEmailAction,
    tokenExpired,
    hasToken,
    getJwt,
    hasAccess
};

export default authentication;
