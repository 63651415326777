import React from "react";
import { GetSsoProvider } from "../../services/ssoManagerService";
import Column from "../../components/common/columns";
import Table, { PublishedTableProps } from "../../components/common/Table";
import authentication from "../../services/authenticationService";

class SsoManagerTable extends React.Component<PublishedTableProps<GetSsoProvider>> {
    columns : Column<GetSsoProvider>[] = [
        { key: "name", label: "Name", order: "asc" }
    ];

    raiseSort = (sortColumn : Column<GetSsoProvider>) => {
        this.setState({sortColumn});
        if ( this.props.onSort !== undefined)
            this.props.onSort(sortColumn);
    }

    handleAuditParams = (item: any) => {
        return {
            entityName : "e_suite.Database.Core.Tables.UserManager.SsoProviders",
            primaryKey : "{\"Id\":"+item.id+"}"
            }
    }

    render() { 
        const { data, sortColumn, onChangePage, onSearch, onDelete } = this.props;
        const editPath = authentication.hasAccess("EditSsoProvider") ? "edit/{0}" : undefined;
        const doDelete = authentication.hasAccess("DeleteSsoProvider") ? onDelete : undefined;
        const showAudit = authentication.hasAccess("ViewAuditLog") ? this.handleAuditParams : undefined;

        return <Table data={data} keyName="id" columns={this.columns} sortColumn={sortColumn} editPath={editPath} onSort={this.raiseSort} onChangePage={onChangePage} onSearch={onSearch} onDelete={doDelete} onAuditParams={showAudit}/>;
    }
}
 
export default SsoManagerTable;