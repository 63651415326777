import { MakeGeneralIdRefParams, GeneralIdRef, MakeGeneralIdRef } from "../utils/GeneralIdRef";
import MapToJson from "../utils/MapToJson";
import httpService from "./httpService";
import { Paginated } from "./Paginated";

const apiEndpoint = "/SsoManager";

export type GetSsoProvider = {
    id : bigint;
    guid : string;
    name : string;
    clientId : string;
    clientSecret : string;
    validIssuer : string;
    authorizationEndpoint : string;
    tokenEndpoint : string;
    deleted : boolean;
}

export async function getSsoProviders( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<GetSsoProvider>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<GetSsoProvider>>(apiEndpoint + "/ssoProviders",
    { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response?.data;
}



export async function getSsoProvider( id? : bigint, guid? : string ): Promise<GetSsoProvider>{
    const params = MakeGeneralIdRefParams(id, guid);

    const response = await httpService.get<GetSsoProvider>(apiEndpoint + "/ssoProvider?" + params );

    return response?.data;
}

export async function postSsoProvider(name : string, clientId : string, clientSecret : string, validIssuer : string, authorizationEndpoint : string, tokenEndpoint : string): Promise<any>{
    return await httpService.post(apiEndpoint + "/ssoProvider", {
        name,
        clientId,
        clientSecret,
        validIssuer,
        authorizationEndpoint,
        tokenEndpoint
    });
}

export async function putSsoProvider( id : GeneralIdRef, name : string, clientId : string, clientSecret : string, validIssuer : string, authorizationEndpoint : string, tokenEndpoint : string): Promise<any>{
    return await httpService.put(apiEndpoint + "/ssoProvider", {
        id,
        name,
        clientId,
        clientSecret,
        validIssuer,
        authorizationEndpoint,
        tokenEndpoint
    });
}

export async function deleteSsoProvider( id? : bigint, guid?: string): Promise<any>{
    const generalIdRef = MakeGeneralIdRef( id, guid);

    return await httpService.delete(apiEndpoint + "/ssoProvider", { data: generalIdRef });
}

const ssoManagerService = {
    getSsoProviders,
    getSsoProvider,
    postSsoProvider,
    putSsoProvider,
    deleteSsoProvider
};

export default ssoManagerService;
