import React from "react";
import Table, { PublishedTableProps } from "../../components/common/Table";
import Column from "../../components/common/columns";
import Button, { ButtonType } from "../../components/common/Button";
import libraryPartService, { ReadLibraryPart } from "../../services/libraryPartService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import authenticationService from "../../services/authenticationService";

interface LibraryPartsTableProps extends PublishedTableProps<ReadLibraryPart>
{

}

class LibraryPartsTable extends React.Component<LibraryPartsTableProps> {
    columns : Column<ReadLibraryPart>[] = [
        { key: "name", label: "Name", order: "asc", content: (item) => { 
            const fileUrl = libraryPartService.GetFileUrl(item!);

            return <><Button keyValue={item} buttonType={ButtonType.secondary} to={fileUrl.downloadUrl} forceHtml={true}><FontAwesomeIcon icon={faFileDownload}/></Button> <Button buttonType={ButtonType.link} to={String(item?.id)} >{item!.name}</Button></>} },
        { key: "fileName", label: "FIlename", order: "asc" }
    ];

    raiseSort = (sortColumn : Column<ReadLibraryPart>) => {
        this.setState({sortColumn});
        if ( this.props.onSort !== undefined)
            this.props.onSort(sortColumn);
    }
    
    render() { 
        const { data, sortColumn, onChangePage, onSearch, onDelete } = this.props;
        const editPath = authenticationService.hasAccess("EditPart") ? "{0}/edit" : undefined
        const doDelete = authenticationService.hasAccess("DeletePart") ? onDelete : undefined;

        return <Table data={ data } keyName="id" columns={this.columns} sortColumn={sortColumn} editPath={editPath} onSort={this.raiseSort} onChangePage={onChangePage} onSearch={onSearch} onDelete={doDelete}/>;
    }
}
 
export default LibraryPartsTable;