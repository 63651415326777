import React, { Component } from 'react';
import Button, { ButtonType } from '../components/common/Button';
import Column from '../components/common/columns';
import Permission from '../components/common/Permission';
import { Paginated } from '../services/Paginated';
import ssoManagerService, { GetSsoProvider } from '../services/ssoManagerService';
import SsoManagerTable from './components/ssoManagerTable';

interface SsoManagerState{
    loaded: boolean;
    pagedData : Paginated<GetSsoProvider>,
    sortColumn : Column<GetSsoProvider>,
    filters: Map<string, string>;
}

class SsoManager extends Component< any, any, SsoManagerState> {
    state = {
        loaded : false,
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "name", label: "Name", order: "asc" },
        filters: new Map<string, string>()
    }

    componentDidMount = async () => {
        const { page, pageSize } = this.state.pagedData;

        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;
        
         const pagedData = await ssoManagerService.getSsoProviders(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters);
         if (pagedData) {
             this.setState({ loaded: true, pagedData });
         }
         else {
             this.setState({ loaded: false });
         }
    }

    onSort = async(sortColumn : Column<GetSsoProvider>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        const pagedData = await ssoManagerService.getSsoProviders(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters);
        if (pagedData) {
            this.setState({ loaded: true, pagedData, sortColumn });
        }
        else {
            this.setState({ loaded: false });
        }
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);

        const pagedData = await ssoManagerService.getSsoProviders(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters);
        if (pagedData) {
            this.setState({ loaded: true, filters, pagedData });
        }
        else {
            this.setState({ loaded: false });
        }
    };

    onDelete = async ( keyValue? : GetSsoProvider) => {
        const response = await ssoManagerService.deleteSsoProvider( keyValue?.id, keyValue?.guid);
        if (response) {
            this.componentDidMount();
        }
    }

    render(): JSX.Element {
        const { pagedData, sortColumn } = this.state;

        return (
            <>
                <Permission privilegeKey="AddSsoProvider">
                <div>
                    <Button buttonType={ButtonType.primary} to="add">Add</Button>
                    </div>
                </Permission>
                <hr/>
                <SsoManagerTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch} onDelete={this.onDelete}/>
            </>
        );
    }
};

export default SsoManager;
