import { Component } from 'react';
import Column from '../components/common/columns';
import { Paginated } from '../services/Paginated';
import Button, { ButtonType } from '../components/common/Button';
import withRouter from '../utils/withRouter';
import LibraryPartsTable from './components/libraryPartsTable';
import libraryPartService, { ReadLibraryPart } from '../services/libraryPartService';
import Permission from '../components/common/Permission';
import { selectFile } from '../utils/fileSelector';
import { toast } from 'react-toastify';

interface LibraryPartsState{
    pagedData : Paginated<ReadLibraryPart>,
    sortColumn : Column<ReadLibraryPart>,
    filters: Map<string, string>;
}

class LocLibraryParts extends Component< any, any, LibraryPartsState> {
    state = {
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "name", label: "Name", order: "asc" },
        filters: new Map<string, string>()
    }

    LibraryId = () : bigint => {
        const { libraryId } = this.props.router.params;
        return libraryId;
    }

    PieceId = () : bigint => {
        const { pieceId } = this.props.router.params;
        return pieceId;
    }

    componentDidMount = async () => {
        const { page, pageSize } = this.state.pagedData;
        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSort = async(sortColumn : Column<ReadLibraryPart>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);
        await this.updatePage(page, pageSize, sortColumn, filters);
    };

    updatePage = async(page : number, pageSize : number, sortColumn : Column<ReadLibraryPart>, filters : Map<string, string> ) =>
    {

        filters.set( "pieceId", String(this.PieceId()));
        const pagedData = await libraryPartService.GetLibraryParts(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
        this.setState({ filters, pagedData, sortColumn });
    }

    onDelete = async ( keyValue? : ReadLibraryPart) => {
        await libraryPartService.DeleteLibraryPart( keyValue?.id, keyValue?.guid);
        toast.success( "Part " + keyValue?.name + " deleted");
        this.componentDidMount();
    }

    HandleDiscoverClick = async () => {
        await libraryPartService.AutoPopulate(this.PieceId());
    }

    HandleUploadClick = async () => {
        let files = await selectFile(".pdf", true);

        let uploadFiles = files as File[];

        this.setState( { uploadFiles });

        await libraryPartService.UploadFiles(uploadFiles, this.PieceId(), undefined );
        toast.info("New Part(s) added");
    }

    render(): JSX.Element {
        const { pagedData, sortColumn } = this.state;

        return (
            <div>
                <Permission privilegeKey="AddPart">
                    <div>
                        <Button className="me-1" buttonType={ButtonType.primary} to="add">Add</Button>
                        <Button buttonType={ButtonType.primary} onClick={this.HandleDiscoverClick} >Discover</Button>
                        <Button className="me-1" buttonType={ButtonType.primary} onClick={this.HandleUploadClick}>Upload</Button>
                        {/* {uploadFile !== null && <Progressbar text={uploadFile?.name??""} percent={uploadPercent??0}/>} */}
                    </div>
                    <hr/>
                </Permission>
                <LibraryPartsTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch} onDelete={this.onDelete}/>
            </div>
        );
    }
};

const LibraryParts = withRouter(LocLibraryParts);


export default LibraryParts;
