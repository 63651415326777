import React from "react";
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paginated } from "../../services/Paginated";
import Button, { ButtonType } from "./Button";
import { InputType } from "./Input";

interface PaginationProps<T> {
    data : Paginated<T>
    onChangePage : (page: number, pageSize : number) => void;
}
 
interface PaginationState {
    
}
 
class Pagination<T> extends React.Component<PaginationProps<T>, PaginationState> {
    state = { }

    changePage( page : number, pageSize : number ){
        const {onChangePage} = this.props;

        onChangePage(page, pageSize);
    }

    clickFirst = () => {
        const { pageSize } = this.props.data;

        this.changePage(1, pageSize );
    }

    clickPrevious = () => {
        const { page, pageSize } = this.props.data;
        
        this.changePage(page - 1, pageSize);
    }

    clickNext = () => {
        const { page, pageSize } = this.props.data;
        
        this.changePage(page + 1, pageSize);
    }

    clickLast = () => {
        const { totalPages, pageSize } = this.props.data;
        
        this.changePage(totalPages, pageSize);
    }

    PageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const input = e.currentTarget;
        const { page } = this.props.data;
    
        let newPageSize : number = +input.value;

        this.changePage(page, newPageSize);
    }

    handlePageSelect = ( e : React.ChangeEvent<HTMLInputElement>) =>
    {
        const { pageSize, totalPages } = this.props.data;
        const input = e.currentTarget;

        const newPage = Number(input.value);

        if (1 <= newPage && newPage <= totalPages) {
            this.changePage(newPage, pageSize);
        }
    }

    render() {
        
        const { data } = this.props;
        
        if (data === null)
            return <></>

        const pageSizeOptions = [{ _id: "10", name: "10" },
        { _id: "25", name: "25" },
        { _id: "50", name: "50" },
        { _id: "100", name: "100" }];

        return ( <div className="justify-content-between pagination">
            <span>
                <select value={data.pageSize} className="form-select" onChange={this.PageSizeChange}>
                    {pageSizeOptions.map(({ _id, name }) => (
                        <option key={_id} value={_id}>
                            {name}
                        </option>
                    ))}
                </select>
            </span>
            <span>
                <Button className="me-1" buttonType={ButtonType.secondary} onClick={this.clickFirst} disabled={data.page < 2}><FontAwesomeIcon icon={faAngleDoubleLeft}/></Button>
                <Button className="me-1" buttonType={ButtonType.secondary} onClick={this.clickPrevious} disabled={data.page < 2}><FontAwesomeIcon icon={faAngleLeft}/></Button>
                <span className="me-1"><input type={InputType.number} value={data.page} min={1} max={data.totalPages} onChange={this.handlePageSelect}/> of {data.totalPages}</span>
                <Button className="me-1" buttonType={ButtonType.secondary} onClick={this.clickNext} disabled={data.page >= data.totalPages}><FontAwesomeIcon icon={faAngleRight}/></Button>
                <Button className="me-1" buttonType={ButtonType.secondary} onClick={this.clickLast} disabled={data.page >= data.totalPages}><FontAwesomeIcon icon={faAngleDoubleRight}/></Button>
                <span>{data.count} Items</span>
            </span>
        </div>);
    }
}
 
export default Pagination;