import React from "react";

interface RedirectProps {
    to : string    
}

interface RedirectState {
    
}

class Redirect extends React.Component<RedirectProps, RedirectState> {
    render() {  
        const {to} = this.props;

        window.location.replace(to);

        return null;
    }
}
 
export default Redirect;