import * as React from "react";
import authentication from "../services/authenticationService";

class Logout extends React.Component {
    componentDidMount() {
        authentication.logout();
        
        if (window.__RUNTIME_CONFIG__.EXTERNAL_LOGIN) {
            window.location.href = "/account/logout"
        }
        else {
            window.location.href = "/"
        }
    }

    render() {
        return <div>Logging out</div>;
    }
}

export default Logout;
