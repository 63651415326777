import Joi from "joi";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form, { FormState } from "../components/common/Form";
import { InputType } from "../components/common/Input";
import ssoManagerService from "../services/ssoManagerService";
import { MakeGeneralIdRef } from "../utils/GeneralIdRef";
import withRouter from "../utils/withRouter";


interface SsoProviderDetailsState extends FormState {
    data: {
        name: string;
        clientId: string,
        clientSecret: string,
        validIssuer: string,
        authorizationEndpoint: string,
        tokenEndpoint: string
    };
    redirect: string;
}

class SsoProviderDetails extends Form<any, any, SsoProviderDetailsState> {
    state: SsoProviderDetailsState = {
        data: {
            name: "",
            clientId: "",
            clientSecret: "",
            validIssuer: "",
            authorizationEndpoint: "",
            tokenEndpoint: ""
        },
        errors: {},
        redirect: "",
    };

    labelName = "Name";
    labelClientId = "Client Id";
    labelClientSecret = "Client Secret";
    labelValidIssuer = "Valid Issuer";
    labelAuthorizationEndpoint = "Authorisation Endpoint";
    labelTokenEndpoint = "Token Endpoint";

    labelApply = "Save";
    labelSave = "Save and close";

    schema = {
        name: Joi.string().required().max(450).label(this.labelName),
        clientId: Joi.string().required().max(450).label(this.labelClientId),
        clientSecret: Joi.string().required().max(450).label(this.labelClientSecret),
        validIssuer: Joi.string().required().max(450).label(this.labelValidIssuer),
        authorizationEndpoint: Joi.string().required().max(450).label(this.labelAuthorizationEndpoint),
        tokenEndpoint: Joi.string().required().max(450).label(this.labelTokenEndpoint)
    };

    doSubmit = async (buttonName : string) => {
        try {
            const { name, clientId, clientSecret, validIssuer, authorizationEndpoint, tokenEndpoint } = this.state.data;

            if (this.isEditMode()) {
                const { ssoProviderId } = this.props.router.params;

                var generalIdRef = MakeGeneralIdRef(ssoProviderId);
                const response = await ssoManagerService.putSsoProvider(generalIdRef, name, clientId, clientSecret, validIssuer, authorizationEndpoint, tokenEndpoint);
                if (response) {
                    toast.info("Sso Provider edited");
                }
            } else {
                const response = await ssoManagerService.postSsoProvider(name, clientId, clientSecret, validIssuer, authorizationEndpoint, tokenEndpoint);
                if (response) {
                    toast.info("New Sso Provider added");
                }
            }

            if (buttonName === this.labelSave)
                this.setState({ redirect: "/ssoManager" });
        }
        catch(ex: any) {
            this.handleGeneralError(ex);
        }
    };

    isEditMode = () => {
        const { editMode } = this.props;
        return editMode;
    };

    componentDidMount = async () => {
        const { ssoProviderId } = this.props.router.params;

        if (ssoProviderId !== undefined) {
            try {
                const loadedData = await ssoManagerService.getSsoProvider(ssoProviderId);
                //if (loadedData) {
                    const { data } = this.state;

                    data.name = loadedData.name;
                    data.clientId = loadedData.clientId;
                    data.clientSecret = loadedData.clientSecret;
                    data.validIssuer = loadedData.validIssuer;
                    data.authorizationEndpoint = loadedData.authorizationEndpoint;
                    data.tokenEndpoint = loadedData.tokenEndpoint;                    

                    this.setState({ data });
                // }
                // else {
                //     this.setState({ loaded: false });
                // }
            }
            catch(ex: any) {
                this.handleGeneralError(ex);
            }
        }

        //if (!this.isEditMode())
            //this.setState({ loaded: true } );
    };

    render() {
        const { redirect } = this.state;
        if (redirect !== "") return <Navigate to={redirect} />;

        let mode = "Add";
        if (this.isEditMode()) mode = "Edit";

        let redirectUrl = window.location.href.slice(0,window.location.href.length - this.props.router.location.pathname.length) + "/account/auth/" + this.props.router.params.ssoProviderId;

        return (
            <>
                <h1>{mode} Sso Provider</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderInput("name", this.labelName, InputType.text)}
                    {this.renderInput("clientId", this.labelClientId, InputType.text)}
                    {this.renderInput("clientSecret", this.labelClientSecret, InputType.text)}
                    {this.renderInput("validIssuer", this.labelValidIssuer, InputType.text)}
                    {this.renderInput("authorizationEndpoint", this.labelAuthorizationEndpoint, InputType.text)}
                    {this.renderInput("tokenEndpoint", this.labelTokenEndpoint, InputType.text)}
                
                    {this.isEditMode() && <div>Redirect URL: {redirectUrl}</div>} 

                    {this.isEditMode() && this.renderButton(this.labelApply)}
                    {this.renderButton(this.labelSave)}
                </form>
            </>
        );
    }
}

const HOCSsoProviderDetails = withRouter(SsoProviderDetails);

export default HOCSsoProviderDetails;
