import React from "react";
import Table, { PublishedTableProps } from "../../components/common/Table";
import Column from "../../components/common/columns";
import libraryPartService, { ReadLibraryPart } from "../../services/libraryPartService";
import Button, { ButtonType } from "../../components/common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

class LibraryViewerPartsTable extends React.Component<PublishedTableProps<ReadLibraryPart>> {
    columns : Column<ReadLibraryPart>[] = [
        { key: "name", label: "Part name", order: "asc", content: (item) =>{
            const fileUrl = libraryPartService.GetFileUrl(item!);
            
            return <>
                {(item.fileName !== "") && <Button keyValue={item} buttonType={ButtonType.secondary} to={fileUrl.downloadUrl} forceHtml={true}><FontAwesomeIcon icon={faFileDownload}/></Button>} 
                {item!.name}</>
            }
        }
    ];

    render() { 
        const { data } = this.props;

        return <Table data={ data } keyName="id" columns={this.columns} showHeader={false} showFooter={false}/>;
    }
}
 
export default LibraryViewerPartsTable;